import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const Chocolate = makeShortcode("Chocolate");
const Flex = makeShortcode("Flex");
const Icon = makeShortcode("Icon");
const Link = makeShortcode("Link");
const Button = makeShortcode("Button");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "bonnes-pratiques-de-sécurité",
      "style": {
        "position": "relative"
      }
    }}>{`Bonnes pratiques de sécurité`}<a parentName="h1" {...{
        "href": "#bonnes-pratiques-de-s%C3%A9curit%C3%A9",
        "aria-label": "bonnes pratiques de sécurité permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Kernel, c'est être prudent, dans tous les sens du terme. En ce qui concerne le code que nous écrivons, cela signifie d'abord la sécurité, puis le travail d'équipe. Vous trouverez ci-dessous les meilleures ressources de sécurité que nous connaissons pour vous aider à vous assurer que vos contrats suivent les meilleures pratiques et respectent les dernières normes de l'industrie.`}</p>
    <h2 {...{
      "id": "outils",
      "style": {
        "position": "relative"
      }
    }}>{`Outils`}<a parentName="h2" {...{
        "href": "#outils",
        "aria-label": "outils permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      marginBottom: '1rem',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate sx={{
        marginBottom: '1.5rem'
      }} mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Boîte à outils Trail of Bits`}</strong></p>
          <p>{`L'un des cabinets d'audit les mieux établis et les plus respectés possède une boîte à outils pour garantir des normes de sécurité élevées.`}</p>
          <p><em parentName="p">{`H/T Matt Solomon. Check out `}<Link to="https://medium.com/coinmonks/ethereum-security-analysis-tools-an-introduction-and-comparison-1096194e64d5" mdxType="Link">{`his article`}</Link>{` for more.`}</em></p>
          <Button sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} to="https://github.com/crytic/eth-security-toolbox" mdxType="Button">
  Secure
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Détecter les contrats pas si intelligents`}</strong></p>
          <p>{`Deux référentiels utiles pour comprendre les modèles vulnérables utilisés par les contrats pas si intelligents et pour `}<Link to="https://github.com/crytic/slither/tree/master/tests/detectors" mdxType="Link" />{` détecter diverses vulnérabilités potentielles.`}</p>
          <Button sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} to="https://github.com/crytic/not-so-smart-contracts" mdxType="Button">
  Detect
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`MythX`}</strong></p>
          <p>{`Un autre outil Consensys utile et facile à intégrer avec des frameworks, en particulier comme Truffle. Trouvez un code utile, parmi beaucoup d'autres choses, `}<Link to="https://www.notion.so/Module-2-Automated-Tooling-22b7b1513dd247d685616d55e27b6877" mdxType="Link">{`ici`}</Link>{`.`}</p>
          <Button sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} to="https://mythx.io/" mdxType="Button">
  Analyse
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`VS Code Solidity Auditeur`}</strong></p>
          <p>{`Un plugin VS Code pratique et utile pour auditer vos contrats intelligents pendant que vous travaillez.˝`}</p>
          <Button sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} to="https://github.com/ConsenSys/vscode-solidity-auditor" mdxType="Button">
  Audit
          </Button>
        </Flex>
      </Chocolate>
    </Box>
    <h2 {...{
      "id": "praxis",
      "style": {
        "position": "relative"
      }
    }}>{`Praxis`}<a parentName="h2" {...{
        "href": "#praxis",
        "aria-label": "praxis permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Là où la pratique et la théorie se rencontrent. Ces pratiques, listes et méthodes indiquées ci-dessous ne sont pas tout à fait des outils au sens de la section ci-dessus, mais elles sont très, très utiles.`}</p>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      marginBottom: '1rem',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate sx={{
        marginBottom: '1.5rem'
      }} mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Liste de contrôle Secureum`}</strong></p>
          <p>{`Une liste de contrôle claire et concise dont tout développeur ou audit sérieux a besoin pour son travail. Écrit par notre propre Rajeev Gopalakrishna.`}</p>
          <Button sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} to="https://secureum.substack.com/p/smart-contract-security-101-secureum" mdxType="Button">
  Check
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Meilleures pratiques de Consensys`}</strong></p>
          <p>{`Un ensemble détaillé d'instructions pour écrire du code sécurisé.`}</p>
          <p><em parentName="p">{`H/T Nazzareno Massari`}</em></p>
          <Button sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} to="https://consensys.github.io/smart-contract-best-practices/" mdxType="Button">
  Learn
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Test de mutation`}</strong></p>
          <p>{`Une introduction aux tests de mutation par le mentor de Security Track, Joran Honig. Vous pouvez trouver une explication détaillée `}<Link to="https://github.com/JoranHonig/publications/blob/master/practical_mutation_testing_for_smart_contracts.pdf" mdxType="Link">{`ici`}</Link>{`.`}</p>
          <Button sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} to="https://joranhonig.nl/introduction-into-mutation/" mdxType="Button">
  Mutate
          </Button>
        </Flex>
      </Chocolate>
    </Box>
    <h2 {...{
      "id": "alertes",
      "style": {
        "position": "relative"
      }
    }}>{`Alertes`}<a parentName="h2" {...{
        "href": "#alertes",
        "aria-label": "alertes permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`La sécurité a autant à voir avec l'information qu'avec la gestion des risques. Nous recommandons ces publications pour vous aider à rester au courant des dernières nouvelles, des développements et des idées de l'industrie (sans avoir à passer toutes vos journées à traquer CT - bien que cela puisse aussi avoir son utilité).`}</p>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      marginBottom: '1rem',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate sx={{
        marginBottom: '1.5rem'
      }} mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`BlockThreat`}</strong></p>
          <p>{`Les dernières informations sur les menaces, les vulnérabilités, les outils de sécurité et les événements liés à la blockchain et à la crypto-monnaie.`}</p>
          <Button sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} to="https://blockthreat.substack.com/" mdxType="Button">
  Learn
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`samczsun`}</strong></p>
          <p>{`Le meilleur blog pour voir comment ne pas écrire de contrats intelligents et découvrir toutes les différentes façons dont votre code pourrait tomber en production.`}</p>
          <Button sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} to="https://samczsun.com/" mdxType="Button">
  Tremble
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Rekt`}</strong></p>
          <p>{`Le dark web journalisme DeFi.`}</p>
          <Button sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} to="https://www.rekt.news" mdxType="Button">
  Learn
          </Button>
        </Flex>
      </Chocolate>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      